<template>
    <v-container fluid>
        <v-dialog
            v-if="postId != null"
            v-model="deleteDialogOpen"
            max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Are you sure?
                </v-card-title>

                <v-card-text>
                    You are about to delete this post.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteDialogOpen = false">
                        No
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="submitRemove(); deleteDialogOpen = false;">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Notification :eventBus=bus />
        <div class="transition-swing text-h6" text>{{ editMode ? 'Update' : 'New'}} {{ enableNews ? '' : 'blog' }} post</div>
        <v-switch
            v-if="!editMode"
            class="mb-4"
            v-model="enableNews"
            label="Create post as highlited news"
            color="primary"
            hide-details
            dense>
        </v-switch>
        <div class="d-flex justify-end" v-if="editMode">
            <v-btn 
                ripple
                small
                outlined
                color="primary"
                @click="deleteDialogOpen = true">
                Delete Post
            </v-btn>
        </div>
        <v-form
            ref="form"
            v-model="isFormValid">

            <v-text-field 
                label="Title" 
                v-model="form.title" 
                :disabled="isBusy">
            </v-text-field>
        </v-form>

        <VueEditor 
            v-if="!enableNews"
            v-model="form.description" 
            :editorToolbar="customToolbar" 
            useCustomImageHandler 
            @image-added="handleImageAdded">
        </VueEditor>
        
        <v-btn color="primary mt-4" @click="submit" v-if="!isBusy">{{ editMode ? 'Update' : 'Create'}}</v-btn>
        <Loader v-if="isBusy" />
    </v-container>
</template>
<script>
import Vue from 'vue';
import Loader from '../shared/Loader';
import Notification from '../shared/Notification';
import ApiService from '../../ApiService';
import { VueEditor } from "vue2-editor";

export default {
    name: 'CreatePost',
    components: {
        Loader, Notification, VueEditor
    },
    data() {
        return {
            bus: new Vue(),
            isFormValid: true,
            isBusy: false,
            form: {
                title: null,
                description: null,
                highlight: null
            },
            customToolbar: [
                ["bold", "italic", "underline"], 
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ list: "ordered" }, { list: "bullet" }], 
                [{ align: "" }, { align: "center" }, { align: "right"}, { align: "justify"}],
                ["link", "image", "video"],
                ["clean"]
            ],
            deleteDialogOpen: false,
            enableNews: false
        }
    },
    watch: {
        $route () {
            if (!this.editMode) {
                this.form.title = null;
                this.form.description = null;
                this.enableNews = false;
            }
        },
        enableNews: function() {
            this.form.description = null;
        }
    },
    computed: {
        editMode: function() {
            let id = Number(this.$route.params.id);
            return id && id >= 1;
        },
        postId: function() {
            return Number(this.$route.params.id);
        }
    },
    methods: {
        addNotification(message) {
            this.bus.$emit('add', message);
        },
        clearNotifications() {
            this.bus.$emit('clear');
        },
        createPost() {
            return new Promise((resolve, reject) => {
                ApiService.createPost(this.form).then(res => {
                    if (res.error) {
                        reject(res.error);
                        return;
                    }

                    resolve();
                })
                .catch(err => reject(err));
            });
        },
        updatePost(postId) {
            return new Promise((resolve, reject) => {
                ApiService.updatePost(postId, this.form).then(res => {
                    if (res.error) {
                        reject(res.error);
                        return;
                    }

                    resolve();
                })
                .catch(err => reject(err));
            });
        },
        submit() {
            this.clearNotifications();

            if (!this.$refs.form.validate())
                return;
            
            if (this.enableNews) {
                this.form.highlight = true;
            }

            this.isBusy = true;
            let operation;

            if (this.editMode) {
                operation = this.updatePost(this.postId);
            } else {
                operation = this.createPost();
            }

            Promise.resolve(operation)
            .then(() => {
                this.$router.push({ name: 'Content' });
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false
            });
        },
        // Here add error checks
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            this.clearNotifications();

            ApiService.uploadDocument('content/upload', {
                Files: [file],
                Fields: {
                    DocumentType: 1
                }
            })
            .then(res => {
                 Editor.insertEmbed(cursorLocation, "image", res.url);
                 resetUploader();
            })
            .catch(err => {
                this.addNotification(err);
            });
        },
        removePost(postId) {
            return new Promise((resolve, reject) => {
                ApiService.removeContent(postId).then(res => {
                    if (res.error) {
                        reject(res.error);
                    }

                    resolve();
                })
                .catch(err => reject(err));
            });
        },
        submitRemove() {
            if (!this.postId)
                return;
            
            this.clearNotifications();

            this.isBusy = true;
            let operation = this.removePost(this.postId);

            Promise.resolve(operation)
            .then(() => {
                this.$router.push({ name: 'Content' });
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false
            });
        },
    },
    created() {
        if (this.editMode && this.postId) {
            ApiService.getContentById(this.postId).then(res => {

                if (res.highlight) {
                    this.enableNews = true;
                    this.form.title = res.title;

                    return;
                }

                this.form.title = res.title;
                this.form.description = res.description;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .imageButton:not(.on-hover) {
        display: none;
    }

    .post-switch {
        font-size: 0.5em !important;
    }
</style>