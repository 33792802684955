<template>
    <v-footer 
        :padless="padless"
        class="ma-0">
        <v-card
            flat
            tile
            width="100%"
            class="lighten-1 text-center" 
            dark>
            <v-card-text>
                <v-btn
                    v-for="(item, i) in items"
                    :key="i"
                    class="mx-4"
                    icon
                    :href="item.src"
                    target="_blank">
                    <v-icon size="24px">
                    {{ item.icon }}
                    </v-icon>
                </v-btn>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                <p><strong>Sandi Sejdinovski &#169; 2015 - {{ new Date().getFullYear() }} </strong></p>
                <p>Developed and designed by <strong><a href="https://www.facebook.com/al.laylah/" target="_blank">Alex Maher</a></strong></p>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>
import ApiService from '../../ApiService';

export default {
    name: 'Footer',
    data() {
        return {
             items: [
                 {
                     icon: 'mdi-facebook',
                     src: 'https://www.facebook.com/sandi.sejdinovski'
                 },
                 {
                     icon: 'mdi-instagram',
                     src: 'https://www.instagram.com/coach_sandi/'
                 },
                 {
                     icon: 'mdi-twitter',
                     src: 'https://twitter.com/CoachSandi'
                 },
                 {
                     icon: 'mdi-linkedin',
                     src: 'https://www.linkedin.com/in/sandisejdinovski/?originalSubdomain=si'
                 },
                 {
                     icon: 'mdi-email',
                     src: 'mailto:sandi@sejdinovski.com'
                 }
            ],
            padless: true,
            news: null
        };
    },
    methods: {
        getNewsFeed() {
            ApiService.getNewsFeed({ PageNumber: 0, PageMax: 10 }).then(res => {
                this.news = res.data;
            });
        }
    },
    created() {
        this.getNewsFeed();
    }
}
</script>