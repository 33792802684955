<template>
    <div>
        <NavMenu />
        <router-view />
        <Footer />
    </div>
</template>

<script>
import NavMenu from '../shared/NavigationMenu';
import Footer from '../shared/Footer';

export default {
    name: 'Home',
    components: {
        NavMenu, Footer
    },
    created() {
    },
    data() {
        return {
        };
    },
    methods: {}
}
</script>