import config from '../config';
import axios from 'axios';

export default class ApiService {
    static async getContentFeed(form) {
        return axios.post(config.ApiBaseUrl + 'content/feed', JSON.stringify(form), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    static async getNewsFeed(form) {
        return axios.post(config.ApiBaseUrl + 'content/newsfeed', JSON.stringify(form), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    static async downloadFile(name) {
        return axios.get(config.ApiBaseUrl + 'document/get/files/' + name, { responseType: 'blob' }).then(res => res.data);
    }

    static async getContentById(id) {
        return axios.get(config.ApiBaseUrl + 'content/get/' + id).then(res => res.data);
    }

    static async getUserSettings() {
        return axios.get(config.ApiBaseUrl + 'user/settings').then(res => res.data);
    }

    static async updateUserSettings(form) {
        return axios.post(config.ApiBaseUrl + 'user/settings/update', JSON.stringify(form), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    static async getUserProfile(name) {
        return axios.get(config.ApiBaseUrl + 'user/profile/' + name).then(res => res.data);
    }

    static async sendUserContactMessage(data) {
        return axios.post(config.ApiBaseUrl + 'user/contact', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    static async getLookup(name) {
        return axios.get(config.ApiBaseUrl + 'lookup/' + name).then(res => res.data);
    }

    static async validateToken(token) {
        return axios.post(config.ApiBaseUrl + 'user/validate', JSON.stringify({ token: token }), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    static async login(email, password) {
        return axios.post(config.ApiBaseUrl + 'user/authenticate', JSON.stringify({ Email: email, Password: password }), 
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    static async register(fields) {
        return axios.put(config.ApiBaseUrl + 'user/register', 
        JSON.stringify({ Email: fields.email, DisplayName: fields.displayName, Password: fields.password }),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async createPost(form) {
        return axios.put(config.ApiBaseUrl + 'content/create', JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    

    static async updatePost(postId, form) {
        return axios.post(config.ApiBaseUrl + ('content/update/' + postId), JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    

    static async getClubs() {
        return axios.get(config.ApiBaseUrl + 'club/fetch').then(res => res.data);
    }

    static async createClub(form) {
        return axios.put(config.ApiBaseUrl + 'club/create', JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async updateClub(form) {
        return axios.post(config.ApiBaseUrl + 'club/update', JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    

    static async removeClub(clubId) {
        return axios.delete(config.ApiBaseUrl + ('club/remove/' + clubId), null,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }


    // Documents API section
    static async removeDocumentCategory(categoryId) {
        return axios.delete(config.ApiBaseUrl + ('document/category/remove/' + categoryId), null,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async removeDocumentFromCategory(documentId) {
        return axios.delete(config.ApiBaseUrl + ('document/category/document/remove/' + documentId), null,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async updateDocumentCategory(categoryId, form) {
        return axios.post(config.ApiBaseUrl + ('document/category/update/' + categoryId), JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async createDocumentCategory(form) {
        return axios.put(config.ApiBaseUrl + 'document/category/create', JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async getDocsByCategory(docCategoryId, form) {
        return axios.post(config.ApiBaseUrl + ('document/category/search/' + docCategoryId), JSON.stringify(form), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }

    // Gallery API section
    static async removeDocumentFromGallery(documentId) {
        return axios.delete(config.ApiBaseUrl + ('gallery/document/remove/' + documentId), null,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async removeGalleryCategory(categoryId) {
        return axios.delete(config.ApiBaseUrl + ('gallery/category/remove/' + categoryId), null,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async updateGalleryCategory(categoryId, form) {
        return axios.post(config.ApiBaseUrl + ('gallery/category/update/' + categoryId), JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async createGalleryCategory(form) {
        return axios.put(config.ApiBaseUrl + 'gallery/category/create', JSON.stringify(form),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

    static async getDocsByGalleryCategory(categoryId, form) {
        return axios.post(config.ApiBaseUrl + ('gallery/category/search/' + categoryId), JSON.stringify(form), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data);
    }


    static async removeContent(postId) {
        return axios.delete(config.ApiBaseUrl + ('content/remove/' + postId), null,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(res => res.data);
    }

   

    static async uploadDocument(url, formData) {
        let form = new FormData();
        formData.Files.forEach(file => {
            form.append('FileUpload.FormFile', file);
        });

        if (formData.Fields) {
            for (let [key, value] of Object.entries(formData.Fields))
            {
                form.append(key, value);
            }
        }

        return axios.post(config.ApiBaseUrl + url, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => res.data);
    }
}