<template>
    <v-container>
         <v-card class="pa-4 mb-8">
             <div class="font-weight-regular caption">Blog posts</div>
             <v-list v-if="posts != null && posts.length > 0">
                <template v-for="(item, index) in posts">
                    <v-divider
                        v-if="index != 0"
                        :key="index"
                    ></v-divider>

                    <v-list-item :key="'vlp-'+index"
                        :to="{ name: 'ContentPost', params: { id: item.id } }"
                        dense
                        ripple>
                        <!-- <v-list-item-avatar>
                            <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar> -->
                        <v-list-item-content>
                            <v-list-item-title class="text-capitalize">{{ item.createdDate }} - {{ item.title }}</v-list-item-title>
                            <!-- <v-list-item-subtitle>{{ item.description | striphtml }}</v-list-item-subtitle> -->
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <div v-else-if="posts != null && posts.length < 1" class="d-flex justify-center">
                <v-subheader>You haven't added any posts yet</v-subheader>
            </div>
            <div v-else class="d-flex justify-center align-center">
                <Loader />
            </div>
            <div class="text-center mt-4" v-if="posts != null && posts.length > 0">
                <v-pagination
                    v-model="postsPage"
                    :length="postsTotalPages"
                    :total-visible="8">
                </v-pagination>
            </div>
         </v-card>

         <v-card class="pa-4">
             <div class="font-weight-regular caption">News posts</div>
             <v-list v-if="news != null && news.length > 0">
                <template v-for="(item, index) in news">
                    <v-divider
                        v-if="index != 0"
                        :key="index"
                    ></v-divider>

                    <v-list-item :key="'vln-'+index"
                        :to="{ name: 'ContentPost', params: { id: item.id } }"
                        dense
                        ripple>
                        <v-list-item-content>
                            <v-list-item-title class="text-capitalize">{{ item.createdDate }} - {{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <div v-else-if="news != null && news.length < 1" class="d-flex justify-center">
                <v-subheader>You haven't added any news yet</v-subheader>
            </div>
            <div v-else class="d-flex justify-center align-center">
                <Loader />
            </div>
            <div class="text-center mt-4" v-if="news != null && news.length > 0">
                <v-pagination
                    v-model="newsPage"
                    :length="newsTotalPages"
                    :total-visible="8">
                </v-pagination>
            </div>
         </v-card>
    </v-container>
</template>
<script>
import ApiService from '../../ApiService';
import Loader from '../shared/Loader';

export default {
    name: 'Content',
    components: {
        Loader
    },
    data() {
        return {
            posts: null,
            postsPage: 1,
            postsTotalPages: 0,
            pageMax: 10,
            news: null,
            newsPage: 1,
            newsTotalPages: 0
        }
    },
    watch: {
        postsPage: function() {
            this.getContentFeed();
        },
        newsPage: function() {
            this.getContentFeed();
        }
    },
    methods: {
        getContentFeed() {
            ApiService.getContentFeed({ PageNumber: (this.postsPage - 1), PageMax: this.pageMax }).then(res => {
                this.posts = res.data;
                this.postsPage = res.currentPage + 1;
                this.postsTotalPages = res.totalPages;
            });
        },
        getNewsFeed() {
            ApiService.getNewsFeed({ PageNumber: (this.newsPage - 1), PageMax: this.pageMax }).then(res => {
                this.news = res.data;
                this.newsPage = res.currentPage + 1;
                this.newsTotalPages = res.totalPages;
            });
        }
    },
    created() {
        this.getContentFeed();
        this.getNewsFeed();
    }
}
</script>