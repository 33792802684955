<template>
    <v-container class="pa-0 mb-0" fluid>
        <v-toolbar id="nav-toolbar">
            <v-toolbar-title>
                <router-link to="/" tag="span" style="cursor: pointer">
                    Sandi Sejdinovski
                </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-xs-only">
                <v-btn
                    v-for="item in menuItems"
                    :key="item.title"
                    :to="item.path"
                    @click="scrollTop">
                    <v-icon class="mr-4" left color="#006400">{{ item.icon }}</v-icon>
                    {{ item.title }}
                </v-btn>
            </v-toolbar-items>   
        </v-toolbar>

        <v-parallax
            :src=images[0].src
            height="100%"
            :class="mainPage ? 'parallax-window-normal' : 'parallax-window-shrink'">

            <v-layout class="d-flex justify-center align-center" elevation="10">
                <v-card tile dark>
                    <v-card-text class="white--text pa-8">
                        <h1 class="font-weight-thin display-2 mb-1">Sandi Sejdinovski</h1>
                        <h1 class="font-weight-thin display-1 mb-1 unselectable">Professional Football Coach</h1>
                        <h1 class="font-weight-thin title mb-1 unselectable">UEFA "A" License</h1>
                        <h1 class="font-weight-thin title unselectable">Slovenia (EU)</h1>
                    </v-card-text>
                </v-card>
                <v-card tile dark>
                    <v-carousel 
                        touchless
                        :continuous="true"
                        :cycle="true"
                        hide-delimiters
                        show-arrows-on-hover
                        :show-arrows=false 
                        height="240px">
                        <v-carousel-item
                            v-for="(item, i) in profileImages"
                            :key="i">
                            <v-img 
                                :src="item.src"
                                :lazy-src="item.src"
                                width="280px"
                                height="240px"
                                :position="item.position || 'center'" />
                        </v-carousel-item>
                    </v-carousel>
                </v-card>
            </v-layout>
        </v-parallax>
    </v-container>
</template>
<script>
import config from '../../../config';

export default {
    name: 'NavigationMenu',
    data() {
        return {
            imageUrl: '/images/header.jpg',
            sidebar: false,
            menuItems: [
                { title: 'Home', path: '/', icon: 'mdi-home' },
                { title: 'Coaches Corner', path: '/blog', icon: 'mdi-soccer' },
                { title: 'Gallery', path: '/gallery', icon: 'mdi-image' },
                { title: 'Documents', path: '/documents', icon: 'mdi-file-document' },
                { title: 'Contact', path: '/contact', icon: 'mdi-email-open' }
            ],
            images: [
                {
                    src: config.AppBaseUrl + '/images/image_3.jpg'
                }
            ],
            profileImages: [
                {
                    src: config.AppBaseUrl + '/images/profile_1.jpg',
                    position: 'top'
                },
                {
                    src: config.AppBaseUrl + '/images/profile_2.jpg'
                },
                {
                    src: config.AppBaseUrl + '/images/profile_3.jpg'
                },
                {
                    src: config.AppBaseUrl + '/images/profile_4.jpg',
                    position: 'bottom'
                }
            ],
            news: null
        }
    },
    created() {
    },
    methods: {
        scrollTop() {
            
        }
    },
    computed: {
        mainPage: function() {
            return this.$route.name == 'HomeOverview';
        }
    }
}
</script>
<style lang="scss" scoped>
    .news-line {
        opacity: 0.2;
    }

    .parallax-window-normal {
        height: 60vh !important;
    }
    .parallax-window-shrink {
        height: 30vh !important;
    }
</style>