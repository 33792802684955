<template>
    <v-container>
        <v-card :max-width=cardWidth class="ma-auto">
            <v-card-text>
                <Notification :eventBus=bus />
                <div class="transition-swing text-h5">Login</div>
                <v-text-field 
                    label="Email" 
                    v-model="form.email" 
                    :disabled="isBusy"></v-text-field>
                <v-text-field 
                    label="Password" 
                    v-model="form.password" 
                    :disabled="isBusy"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="submit" v-if="!isBusy">Login</v-btn>
                <Loader v-if="isBusy" hidden />
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Notification from './shared/Notification';
import Loader from './shared/Loader';

export default {
    name: 'Login',
    components: {
        Notification,
        Loader
    },
    created: function() {
        if (this.isAuthenticated) {
            this.$router.push({ name: 'Home' });
        }
    },
    data: function() {
        return {
            bus: new Vue(),
            showPassword: false,
            form: {
                email: null,
                password: null
            },
            isBusy: false,
            classes: [
                ['h2', 'Heading 4', '2.125rem', '400', '.0073529412em', 2],
            ]
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
        cardWidth() {
            let bpName = this.$vuetify.breakpoint.name;
            if (bpName == 'xs') {
                return '100%';
            } else {
                return 300;
            }
        }
    },
    methods: {
        submit(e) {
            if (e.keyCode && e.keyCode != 13) {
                return;
            }

            this.bus.$emit('clear');

            if (!this.validate()) {
                this.bus.$emit('add', 'Missing required fields');
                return;
            }

            this.isBusy = true;
            this.$store.dispatch('authRequest', this.form).then(() => {
                let route = this.$route.query.redirect || { name: 'Home' };
                this.$router.push(route);
            })
            .catch(err => {
                this.bus.$emit('add', err);
            })
            .finally(() => this.isBusy = false);
        },
        validate() {
            return this.form.email != null && this.form.password != null;
        }
    },
}
</script>
<style lang="scss" scoped>
.container {
    margin-top: 100px;
}
</style>