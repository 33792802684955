import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store.js';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify';
import PrimeVue from "primevue/config";
import './assets/tailwind.css'

axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
    let token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, err => {
    console.log(err);
});

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(PrimeVue, { unstyled: false });

Vue.filter('striphtml', function (value) {
    var div = document.createElement("div");
    div.innerHTML = value;
    var text = div.textContent || div.innerText || "";
    return text;
});

new Vue
({
    render: h => h(App),
    store,
    vuetify,
    router
}).$mount('#app');
