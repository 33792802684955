import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from './ApiService';
// import createPersistedState from 'vuex-persistedstate';
// import * as Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
    // plugins: [createPersistedState({
    //     storage: {
    //         getItem: (key) => Cookies.get(key),
    //         setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
    //         removeItem: (key) => Cookies.remove(key)
    //     }
    // })],
    state: {
        locale: 'en',
        token: localStorage.getItem('token') || null,
        currentUser: null
    },
    getters: {
        isAuthenticated(state) {
            return state.token != null;
        },
        authToken(state) {
            return state.token;
        },
        currentUser(state) {
            return state.currentUser;
        }
    },
    mutations: {
        setLang: (state, lang) => state.locale = lang,
        setToken: (state, token) => state.token = token,
        clearAuth: (state) => {
            state.token = null;
            state.currentUser = null;
        },
        setCurrentUser: (state, userData) => state.currentUser = userData
    },
    actions: {
        setLang(context, lang) {
            context.commit('setLang', lang);
        },
        getCurrentUser({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.token != null) {
                    ApiService.getCurrentUserProfile().then(res => {
                        if (!res.data.error) {
                            commit('setCurrentUser', res.data);
                            resolve();
                        } else {
                            reject(res.data.error);
                        }
                    })
                    .catch(err => reject(err));
                } else {
                    resolve();
                }
            });
        },
        authRequest({commit}, credentials) {
            return new Promise((resolve, reject) => {
                if (credentials && credentials.email && credentials.password) {
                    ApiService.login(credentials.email, credentials.password).then(res => {
                        if (!res.error) {
                            localStorage.setItem('token', res.token);
                            commit('setToken', res.token);
                            resolve();
                        }
                        else {
                            reject(res.error);
                        }
                    })
                    .catch((err, e) => {
                        console.log(e);
                        console.log(err.response.status);
                    });
                }
                else {
                    reject('Missing credentials');
                }
            });
        },
        authLogout({commit}) {
            return new Promise((resolve) => {
                localStorage.removeItem('token');
                commit('clearAuth');
                resolve();
            });
        }
    }
});