<template>
    <div>
        <v-container>
            <v-tabs v-model="tab">
                <v-tab
                    v-for="(item, i) in items"
                    :key="i"
                    :to="{ name: item.pathName }">
                    {{item.name}}
                </v-tab>
                <v-spacer></v-spacer>
                <v-tab @click="submitLogout">
                    Logout
                </v-tab>
            </v-tabs>
        </v-container>
        <router-view></router-view>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'Manage',
    data() {
        return {
            tab: null,
            items: [
                {
                    name: 'Clubs',
                    pathName: 'Clubs'
                },
                {
                    name: 'Posts',
                    pathName: 'Content'
                },
                {
                    name: 'Create Post',
                    pathName: 'ContentPost'
                },
                {
                    name: 'Documents',
                    pathName: 'Documents'
                },
                {
                    name: 'Gallery',
                    pathName: 'Gallery'
                },
                {
                    name: 'Settings',
                    pathName: 'Settings'
                }
            ]
        }
    },
    methods: {
        ...mapActions(['authLogout']),
        submitLogout() {
            this.authLogout().then(() => {
                this.$router.push({ name: 'HomeOverview' });
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 996px !important;
    }
</style>