<template>
    <v-timeline-item
        color="primary"
        small>
        <v-card class="pa-4">
            <v-card-actions class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-btn 
                    ripple
                    small
                    outlined
                    color="primary"
                    @click="removeRole(role)">
                    Remove
                </v-btn>
            </v-card-actions>

            <v-textarea
                class="pt-0 mt-0"
                rows="2"
                no-resize
                label="Role description" 
                v-model="role.name" 
                :disabled="disabled"
                :rules="roleDescriptionRules">
            </v-textarea>
            <v-row>
                <v-col>
                    <v-menu
                        ref="menu1"
                        v-model="startDateMenuVisible"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto">
                    
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :disabled="disabled"
                                v-model="role.startDate"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="startDateRules">
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startDate"
                            type="date"
                            no-title
                            scrollable
                            @input="startDateMenuVisible = false;">
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <v-col>
                    <v-menu
                        ref="menu2"
                        v-model="endDateMenuVisible"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto">
                    
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :disabled="disabled"
                                v-model="role.endDate"
                                label="End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="endDateRules">
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            type="date"
                            no-title
                            scrollable
                            @input="endDateMenuVisible = false;">
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card>
    </v-timeline-item>
</template>

<script>
export default {
    name: 'TimelineRoleForm',
    props: {
        disabled: Boolean,
        role: Object
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            formattedStartDate: null,
            formattedEndDate: null,
            startDateMenuVisible: false,
            endDateMenuVisible: false,
            roleDescriptionRules: [
                v => !!v || 'Role description is required'
            ],
            startDateRules: [
                v => !!v || 'Start date is required'
            ],
            endDateRules: [
                v => !!v || 'End date is required'
            ]
        }
    },
    watch: {
        startDate() {  
            this.role.startDate = this.formatDate(this.startDate);
        },
        endDate() { 
            this.role.endDate = this.formatDate(this.endDate);
        }
    },
    methods: {
        formatDate(date) {
            if (!date) 
                return null;

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) 
                return null;

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        removeRole(item) {
            this.$emit('remove', item);
        }
    },
    created() {
    }
}
</script>