import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import ApiService from './ApiService';
import config from '../config';

Vue.use(VueRouter);

import Index from './components/Home/Home';
import Login from './components/Login';
import ErrorPage from './components/Error';
import HomeContact from './components/Home/Contact';
import Overview from './components/Home/Overview';
import HomeDocuments from './components/Home/Documents';
import HomeGallery from './components/Home/Gallery';
import HomeBlog from './components/Home/Blog';
import HomeBlogPreview from './components/Home/BlogPreview';

import Manage from './components/Manage/Manage';
import Content from './components/Manage/Content';
import CreatePost from './components/Manage/CreatePost';
import Clubs from './components/Manage/Clubs';
import Documents from './components/Manage/Documents';
import Gallery from './components/Manage/Gallery';
import Settings from './components/Manage/Settings';

const routes = [
    { path: '/', component: Index, name: 'Home', 
        children: [ 
            { path: '', component: Overview, name: 'HomeOverview' },
            { path: 'contact', component: HomeContact, name: 'HomeContact' },
            { path: 'documents', component: HomeDocuments, name: 'HomeDocuments' },
            { path: 'gallery', component: HomeGallery, name: 'HomeGallery' },
            { path: 'blog', component: HomeBlog, name: 'HomeBlog' },
            { path: 'blog/:id', component: HomeBlogPreview, name: 'HomeBlogPreview' },
        ] 
    },
    { path: '/login', component: Login, name: 'Login' },
    { path: '/error', component: ErrorPage, name: 'Error' },

    { path: '/manage', component: Manage, name: 'Manage', meta: { requiresAuth: true }, 
        children: [ 
            { path: '', component: Clubs, name: 'Clubs' },
            { path: 'content', component: Content, name: 'Content' },
            { path: 'content/post/:id?', component: CreatePost, name: 'ContentPost' },
            { path: 'documents', component: Documents, name: 'Documents' },
            { path: 'gallery', component: Gallery, name: 'Gallery' },
            { path: 'settings', component: Settings, name: 'Settings' }
        ]
    }
];

const router = new VueRouter({ 
    mode: 'history', 
    routes,
    base: config.AppBaseName
});

router.onError(err => {
    console.log('Router error: ' + err);
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((res) => res.meta && res.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated)
        {
            next({ name: 'Login', query: { redirect: to.fullPath }});
        }
        else {
            ApiService.validateToken(store.getters.authToken).then(res => {
                if (res.error) {
                    store.dispatch('authLogout').then(() => {
                        next({
                            name: 'Login',
                            query: { redirect: to.fullPath }
                        });
                    });

                    return;
                }

                next();
            })
            .catch(() => {
                next({ name: 'Error' });
            });
        }
    }
    else {
        next();
    }
});

// router.beforeEach((to, from, next) => {
//     store.dispatch('getCurrentUser').then(() => {
//         next();
//     })
//     .catch((err) => {
//         if (err.response.status == 401) {
//             store.dispatch('authLogout').then(() => {
//                 next();
//             });
//         }
//         else {
//             next();
//         }
//     });
// });

export default router;