<template>
    <v-container fluid>
        <Notification :eventBus=bus />
        <v-dialog
            v-if="selectedClub != null"
            v-model="deleteDialogOpen"
            max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Are you sure?
                </v-card-title>

                <v-card-text>
                    You are about to delete club: {{ selectedClub.name }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteDialogOpen = false">
                        No
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="submitRemove(selectedClub.id); deleteDialogOpen = false;">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <v-row>
                <v-col 
                    md="5" sm="6">
                    <!-- LEFT SIDE -->
                    <div class="transition-swing text-h6 pl-4">Timeline</div>
                     <v-list v-if="clubs != null && clubs.length > 0">
                        <template>
                            <v-list-item 
                                dense
                                ripple
                                @click="resetForm">
                                <v-list-item-avatar
                                    size="40">
                                    <v-icon>mdi-plus</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <span class="caption font-weight-light">Create new club</span>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-for="(item, index) in clubs">
                            <v-divider
                                v-if="index != 0"
                                :key="index"
                                inset
                                color="white">
                            </v-divider>
                            <v-list-item 
                                dense
                                ripple
                                :class="selectedClub == item ? 'v-list-item--active' : ''"
                                @click="previewClub(item)"
                                :key="'item-'+index">
                                <v-list-item-avatar
                                    size="40">
                                    <v-img 
                                        :src="item.image"
                                        :lazy-src="item.image">
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="item.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                    <div v-else-if="clubs != null && clubs.length < 1">
                        No clubs added yet
                    </div>
                    <div v-else class="d-flex justify-center align-center">
                        <Loader />
                    </div>
                </v-col>

                <!-- RIGHT HAND -->
                <v-col md="7" sm="6">
                    <div class="transition-swing text-h6 pl-4">{{selectedClub != null ? 'Your Club' : 'New Club' }}</div>

                    <div class="d-flex justify-end pa-4" v-if="selectedClub != null">
                        <v-btn 
                            ripple
                            small
                            outlined
                            color="primary"
                            @click="deleteDialogOpen = true">
                            Delete Club
                        </v-btn>
                    </div>

                    <v-form 
                        ref="form"
                        class="pa-4">
                    <v-text-field 
                        label="Name" 
                        v-model="form.clubName" 
                        :disabled="isBusy"
                        :rules="clubNameRules">
                    </v-text-field>

                    <Upload 
                        label="Logo" 
                        placeholder="Choose photo to upload"
                        @onupload="processFiles"
                        :disabled="isBusy"
                        v-if="!imagePreviews.length && !isFileProcessing"
                        :multiple="true" />

                    <div v-else class="d-flex justify-center align-center">
                        <v-hover class="mb-4"
                            v-slot="{hover}">
                            <v-img
                                ref="image-preview"
                                :src=imagePreviews[0].preview
                                :lazy-src=imagePreviews[0].preview
                                aspect-ratio="1"
                                max-width="160">

                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5">
                                        </v-progress-circular>
                                    </v-row>
                                </template>

                                <v-btn 
                                    color="transparent" 
                                    @click="removeImage(0)" 
                                    width="100%" 
                                    height="100%"
                                    :class="{ 'on-hover': hover, 'imageButton': true }"
                                    :disabled="isBusy">

                                    <v-icon color="rgba(255, 255, 255, 0.3)" large>mdi-close</v-icon>
                                </v-btn>
                            </v-img>
                        </v-hover>
                    </div>

                    <v-subheader>Timeline of your roles in the club. Use + button to add more roles.</v-subheader>

                    <v-timeline dense class="mb-8">
                        <v-slide-x-reverse-transition
                            group
                            hide-on-leave>

                        <TimelineRoleForm 
                            v-for="(role, index) in form.roles" 
                            :key="index" 
                            :role="role" 
                            :isBusy="isBusy"
                            @remove="removeRole" 
                        />

                        </v-slide-x-reverse-transition>
                        <v-timeline-item>
                            <template slot="icon">
                                <v-btn
                                    @click="addExampleRole"
                                    fab
                                    dark
                                    outlined
                                    x-small>
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-timeline-item>
                    </v-timeline>
                    <v-btn type="button" color="primary" @click="submit" v-if="!isBusy">{{selectedClub ? 'Update': 'Save Club'}}</v-btn>
                    <div v-else class="d-flex justify-center align-center">
                        <Loader />
                    </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import Vue from 'vue';
import Upload from '../shared/Upload';
import Loader from '../shared/Loader';
import Notification from '../shared/Notification';
import ApiService from '../../ApiService';
import TimelineRoleForm from '../shared/TimelineRoleForm';

export default {
    name: 'Manage',
    components: {
        Upload, Notification, Loader, TimelineRoleForm
    },
    data() {
        return {
            bus: new Vue(),
            form: {
                clubName: null,
                roles: []
            },
            imagePreviews: [],
            isBusy: false,
            isFileProcessing: false,
            uploadLimit: 1,
            clubs: null,
            selectedClub: null,
            clubNameRules: [
                v => !!v || 'Club name is required'
            ],
            deleteDialogOpen: false
        }
    },
    computed: {
        rand: function() {
            return Math.random();
        }
    },
    methods: {
        previewClub(club) {
            if (!club || this.selectedClub == club)
                return;

            this.form.clubName = club.name;
            this.form.roles = club.roles;

            this.imagePreviews = [];
            this.imagePreviews[0] = { preview: club.image };
            this.selectedClub = club;
        },
        resetForm() {
            this.form.clubName = null;
            this.form.roles = [];
            this.imagePreviews = [];
            this.selectedClub = null;
            
            this.$refs.form.resetValidation();

            this.addExampleRole();
        },
        addExampleRole() {
            this.form.roles.push({ name: null, startDate: null, endDate: null });
        },
        removeRole(role) {
            this.form.roles.splice(this.form.roles.indexOf(role), 1);
        },
        getClubs() {
            ApiService.getClubs().then(res => this.clubs = res);
        },
        submitRemove(clubId) {
            this.bus.$emit('clear');
            this.isBusy = true;
            let operation = this.removeClub(clubId);

            Promise.resolve(operation)
            .then(() => {
                this.resetForm();
                this.getClubs();
            })
            .catch(err => {
                this.bus.$emit('add', err)
            })
            .finally(() => {
                this.isBusy = false
            });
        },
        submit() {
            this.bus.$emit('clear');

            if (!this.$refs.form.validate())
                return;
            
            if (this.imagePreviews.length < 1) {
                this.bus.$emit('add', 'You must upload at least 1 photo or video')
                return;
            }

            this.isBusy = true;
            let operation;

            if (this.selectedClub) {
                operation = this.updateClub(this.selectedClub.id);
            } else {
                operation = this.createClub();
            }

            Promise.resolve(operation)
            .then(() => {
                if (!this.selectedClub) {
                    this.resetForm();
                }

                this.getClubs();
            })
            .catch(err => {
                this.bus.$emit('add', err)
            })
            .finally(() => {
                this.isBusy = false
            });
        },
        submitUpdateClubLogo() {
            this.bus.$emit('clear');
            this.isBusy = true;
            let operation = this.updateClubLogo(this.selectedClub.id);

            Promise.resolve(operation)
            .then(() => {
                this.getClubs();
            })
            .catch(err => {
                this.bus.$emit('add', err)
            })
            .finally(() => {
                this.isBusy = false
            });
        },
        removeClub(id) {
            return new Promise((resolve, reject) => {
                ApiService.removeClub(id).then(res => {
                    if (res.error) {
                        reject(res.error);
                    }

                    resolve();
                })
                .catch(err => reject(err));
            });
        },
        updateClub(id) {
            return new Promise((resolve, reject) => {
                ApiService.updateClub({ Id: id, Name: this.form.clubName, Roles: this.form.roles }).then(res => {
                    if (res.error) {
                        reject(res.error);
                        return;
                    }

                    resolve();
                })
                .catch(err => reject(err));
            });
        },
        updateClubLogo(clubId) {
            return new Promise((resolve, reject) => {
                ApiService.uploadDocument('club/upload', { 
                    Files: this.imagePreviews.map(prev => prev.file), Fields: { ClubId: clubId }
                }).then(docRes => {
                    if (docRes.error) {
                        reject(docRes.error);
                        return;
                    }
                    
                    resolve();
                })
                .catch(err => reject(err));
            });
        },
        createClub() {
            return new Promise((resolve, reject) => {
                ApiService.createClub({ Name: this.form.clubName, Roles: this.form.roles }).then(res => {
                    if (res.error) {
                        reject(res.error);
                        return;
                    }

                    if (!res.clubId) {
                        reject('Something went wrong');
                        return;
                    }

                    ApiService.uploadDocument('club/upload', { 
                        Files: this.imagePreviews.map(prev => prev.file), Fields: { ClubId: res.clubId }
                    }).then(docRes => {
                        if (docRes.error) {
                            reject(docRes.error);
                            return;
                        }
                        
                        resolve();
                    })
                    .catch(err => reject(err));
                })
                .catch(err => reject(err));
            });
        },
        processFiles(files) {
            if (this.imagePreviews.length >= this.uploadLimit) {
                this.bus.$emit('add', 'Too many files');
                return;
            }

            if ((this.imagePreviews.length + files.length) >= this.uploadLimit) {
                files = files.splice(0, (this.uploadLimit - this.imagePreviews.length));
            }
            
            let instance = this;
            this.isFileProcessing = true;

            files.forEach((file, index, array) => {
                if (index >= (array.length - 1))
                {
                    instance.isFileProcessing = false;
                }

                let reader = new FileReader();
                reader.onload = function(e) {
                    instance.imagePreviews.push({ preview: e.target.result, file: file });
                }

                reader.onloadend = function() {
                    console.log(instance.imagePreviews.length);
                    if (instance.selectedClub != null && instance.imagePreviews.length >= 1)
                    {
                        instance.submitUpdateClubLogo();
                    }
                }
                
                reader.readAsDataURL(file);
            });
        },
        removeImage(index) {
            this.imagePreviews.splice(index, 1);
        }
    },
    created() {
        this.getClubs();
        this.addExampleRole();
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 996px !important;
    }
</style>