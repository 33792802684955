<template>
    <v-container>
        <h1 class="font-weight-thin display-2 mb-6 mt-10 text-center" text>Coaches Corner</h1>
        <template v-if="news != null">
            <v-lazy
                v-for="(item, i) in newsData"
                :key="i">
                <v-card
                    class="mb-4 pa-6 pb-2"
                    elevation="2">
                    <v-card-title class="mb-2">
                        <router-link :to="{ name: 'HomeBlogPreview', params: { id: item.id }}" v-text="item.title" class="font-weight-normal text-h5 content-link"></router-link>
                    </v-card-title>
                    <v-card-subtitle class="text-caption">{{item.createdDate}}</v-card-subtitle>
                    <v-card-text v-text="item.description" class="mb-4"></v-card-text>
                </v-card>
            </v-lazy>
        </template>
        <div v-else class="d-flex justify-center align-center">
            <Loader />
        </div>
        <div class="text-center mt-8" v-if="news != null && news.length > 0">
            <v-pagination
                v-model="newsPage"
                :length="newsTotalPages"
                :total-visible="8">
            </v-pagination>
        </div>
    </v-container>
</template>
<script>
import Vue from 'vue';
import ApiService from '../../ApiService';
import Loader from '../shared/Loader';

export default {
    name: 'HomeBlog',
    components: {
        Loader
    },
    watch: {
        newsPage: function() {
            this.getContentFeed();

            var top = document.getElementById("top").offsetTop;
            window.scrollTo(0, top);
        }
    },
    computed: {
        newsData: function() {
            let data = this.news;
            return data;
        }
    },
    data() {
        return {
            news: null,
            newsPage: 1,
            newsTotalPages: 0,
            pageMax: 10
        };
    },
    created() {
        this.getContentFeed().then(() => {
            Vue.nextTick(() => this.$emit('init'));
        });
    },
    methods: {
        getContentFeed() {
            return ApiService.getContentFeed({ PageNumber: (this.newsPage - 1), PageMax: this.pageMax }).then(res => {
                if (res) {
                    this.news = res.data;
                    this.newsPage = res.currentPage + 1;
                    this.newsTotalPages = res.totalPages;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 1000px !important;
        margin-bottom: 100px;
    }

    .content-link {
        text-decoration: none;
        color: rgb(34, 34, 34);
    }
</style>