<template>
    <v-file-input
        ref="form"
        :accept="accept || 'image/png, image/jpeg, image/bmp'"
        :placeholder="placeholder || 'Your photos or videos'"
        prepend-icon="mdi-camera"
        :label="label || 'Media'"
        v-model="files"
        truncate-length="16"
        @change="submit"
        :multiple="multiple || false"
        clearable
        :disabled="disabled"
        :hint="hint || null"
        :counter="counter || false">
    </v-file-input>
</template>
<script>

export default {
    name: 'Uploader',
    props: {
        placeholder: String,
        label: String,
        disabled: Boolean,
        accept: String,
        hint: String,
        counter: Boolean,
        multiple: Boolean,
        bus: Object
    },
    created() {
        if (this.bus) {
            this.bus.$on('reset', this.resetForm);
        }
    },
    data() {
        return {
            files: []
        }
    },
    methods: {
        resetForm() {
            if (this.$refs.form) {
                this.$refs.form.reset();
            }
        },
        submit() {
            this.$emit('input', this.files);
            this.$emit('onupload', this.files);
        }
    }
}
</script>