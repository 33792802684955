<template>
    <v-container fluid class="pa-0 ma-0">
         <v-card tile outlined class="d-flex justify-center pa-0" v-if="contractFree">
            <div class="pa-8">
                <h1 class="font-weight-light display-1 text-center mb-2">
                    CONTRACT FREE
                </h1>
                <div class="overline text-center">Available for new coaching challenge worldwide</div>
            </div>
        </v-card>

        <v-card tile outlined class="d-flex justify-center align-center pt-2 pb-2" color="#006400">
            <v-icon class="mr-4" color="white">
                mdi-soccer
            </v-icon>
            <div class="overline text-center white--text">Club Highlights</div>
            <v-icon class="ml-4" color="white">
                mdi-soccer
            </v-icon>
        </v-card>
        
        <v-list 
            class="mt-8"
            v-if="clubsList != null && clubsList.length > 0">
                <template v-for="(item, index) in clubsList">
                    <v-list-item 
                        class="mb-4"
                        :key="index">
                        <v-list-item-content>
                            <h5 :class="`font-weight-light mb-6 display-1 text-center`">
                                {{item.name}}
                            </h5>
                            <div class="d-flex justify-center mb-2">
                                <v-img 
                                    :src="item.image"
                                    :lazy-src="item.image"
                                    aspect-ratio="1"
                                    max-width="120">
                                </v-img>
                            </div>
                            <div>
                                <v-timeline>
                                    <v-timeline-item
                                        v-for="(role, i) in item.roles"
                                        :key="i"
                                        right
                                        color="grey"
                                        icon="mdi-soccer"
                                        >
                                        <div class="club-role font-weight-medium py-3">
                                            {{role.name}}
                                        </div>
                                        <template v-slot:opposite>
                                            <span class="body-2 font-weight-regular">{{getDateFormat(role.startDate) +' - '+ getDateFormat(role.endDate)}}</span>
                                        </template>
                                    </v-timeline-item>
                                </v-timeline>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
    </v-container>
</template>
<script>
import ApiService from '../../ApiService';

export default {
    name: 'Overview',
    data() {
        return {
            clubsList: [],
            contractFree: false
        };
    },
    async created() {
        let clubsResponse = await ApiService.getClubs();
        this.clubsList = clubsResponse;

        let settingsResponse = await ApiService.getUserSettings();
        this.contractFree = settingsResponse.contractFree;
    },
    methods: {
        getDateFormat(date) {
            const [day,month,year] = date.split('/');
            let d = new Date(year, Number(month) - 1, day);
            let m = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(d);

            return `${m} ${year}`
        },
    }
}
</script>
<style lang="scss" scoped>
    .club-role {
        font-size: 1.3em;
    }
</style>
