<template>
    <v-container v-if="documentCats != null">
        <h1 class="font-weight-thin display-2 mb-6 mt-10 text-center" text>Documents</h1>
        <v-select
            class="mb-6 category-menu"
            return-object
            v-model="selectedCategory"
            :items="documentCats"
            menu-props="auto"
            label="Select category"
            hide-details
            single-line
            item-text="name">
        </v-select>

        <div 
            class="mb-8 documents-list" 
            v-if="documents != null">
            <v-list v-if="documents.length > 0">
                <template v-for="(item, index) in documents">
                    <v-divider
                        v-if="index != 0"
                        :key="index">
                    </v-divider>

                    <v-hover 
                        :key="'hv'+index"
                        v-slot="{ hover }">
                        <v-list-item :key="'vlp-'+index"
                            dense>
                            <v-list-item-avatar>
                                <v-icon>{{getFileIcon(item.fileExtension)}}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-capitalize">{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.createdDate }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="hover">
                                <v-btn icon small @click="download(item)">
                                    <v-icon color="grey lighten-1">mdi-download</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-hover>
                </template>
            </v-list>
            <div v-else-if="documents.length < 1" class="d-flex justify-center">
                <v-subheader>No documents in this category yet</v-subheader>
            </div>
            <div class="text-center mt-4" v-if="documents.length > 0">
                <v-pagination
                    v-model="documentsPage"
                    :length="documentsTotalPages"
                    :total-visible="8">
                </v-pagination>
            </div>
        </div>
        <div v-else class="d-flex justify-center align-center">
            <Loader />
        </div>
    </v-container>
</template>
<script>
import ApiService from '../../ApiService';
import Loader from '../shared/Loader';

export default {
    name: 'HomeDocuments',
    components: {
        Loader
    },
    watch: {
        selectedCategory: function() {
            if (!this.selectedCategory)
                return;
            
            this.documentsPage = 1;
            this.getDocuments();
        },
        documentsPage: function() {
            this.getDocuments();
        }
    },
    data() {
        return {
            documentCats: null,
            selectedCategory: null,
            documents: null,
            documentsPage: 1,
            documentsTotalPages: 0,
            pageMax: 10
        };
    },
    created() {
        ApiService.getLookup('documentcats/true').then(res => {
            this.documentCats = res;
            
            if (this.documentCats.length > 0) {
                this.selectedCategory = this.documentCats[0];
            }
        })
        .catch(err => {
            console.log(err);
        });
    },
    methods: {
        getFileIcon(item) {
            switch (item)
            {
                case '.pdf': return 'mdi-file-pdf';
                case '.doc':
                case '.docx':
                    return 'mdi-file-word';
                case '.xls':
                    return 'mdi-file-excel';
                
                default:
                    return 'mdi-file-document';
            }

        },
        download(item) {
            if (!item) {
                return;
            }

            ApiService.downloadFile(item.fileName).then(res => {
                var blob = new Blob([res], { type: 'application/octet-stream' });
                var data = URL.createObjectURL(blob);

                var el = document.createElement('a');
                el.href = data;
                el.download = item.name + item.fileExtension;
                el.click();
                el.remove();
                URL.revokeObjectURL(data);
            })
            .catch(err => {
                console.log(err);
            });
        },
        getDocuments(categoryId) {
            categoryId = categoryId || this.selectedCategory.id;

            if (!categoryId)
                return;

            ApiService.getDocsByCategory(categoryId, {PageNumber: (this.documentsPage - 1), PageMax: this.pageMax }).then(res => {
                this.documents = res.data;
                this.documentsPage = res.currentPage + 1;
                this.documentsTotalPages = res.totalPages;
            })
            .catch(err => {
                console.log(err);
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 1000px !important;
        margin-bottom: 100px;
    }

    .category-menu {
        max-width: 400px !important;
        margin: auto !important;
    }
</style>