<template>
    <v-container v-if="documentCats != null">
        <h1 class="font-weight-thin display-2 mb-6 mt-10 text-center" text>Gallery</h1>
        <v-select
            class="mb-6 category-menu"
            return-object
            v-model="selectedCategory"
            :items="documentCats"
            menu-props="auto"
            label="Select category"
            hide-details
            single-line
            item-text="name">
        </v-select>

        <div 
            class="mb-8 documents-list" 
            v-if="documents != null">
            <v-row
                class="pr-4">
                <v-col cols="3"
                    v-for="(doc, index) in documents" 
                    :key="index">
                    <v-hover v-slot="{hover}">
                        <v-card tile flat outlined>
                            <v-img
                                :src="doc.thumbnail + '/512'"
                                :lazy-src="doc.thumbnail + '/512'"
                                aspect-ratio="1">

                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5">
                                        </v-progress-circular>
                                    </v-row>
                                </template>

                                <v-btn 
                                    @click="showImage(index)"
                                    color="transparent" 
                                    width="100%" 
                                    height="100%"
                                    :class="{ 'on-hover': hover, 'imageButton': true }">
                                </v-btn>
                            </v-img>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <div class="text-center mt-4" v-if="documents != null && documents.length > 0">
                <v-pagination
                    v-model="documentsPage"
                    :length="documentsTotalPages"
                    :total-visible="8">
                </v-pagination>
            </div>
        </div>
        <div v-else class="d-flex justify-center align-center">
            <Loader />
        </div>

        <VueEasyLightbox
            escDisabled
            :visible="lightboxVisible"
            :imgs="images"
            :index="lightboxIndex"
            @hide="handleHide">
        </VueEasyLightbox>
    </v-container>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import ApiService from '../../ApiService';
import Loader from '../shared/Loader';

export default {
    name: 'HomeGallery',
    components: {
        Loader, VueEasyLightbox
    },
    watch: {
        selectedCategory: function() {
            if (!this.selectedCategory)
                return;
            
            this.documentsPage = 1;
            this.getDocuments();
        },
        documentsPage: function() {
            this.getDocuments();
        }
    },
    computed: {
        images: function() {
            return !this.documents ? [] : this.documents.map(x => x.url);
        }
    },
    data() {
        return {
            documentCats: null,
            selectedCategory: null,
            documents: null,
            documentsPage: 1,
            documentsTotalPages: 0,
            pageMax: 100,
            lightboxVisible: false,
            lightboxIndex: 0
        };
    },
    created() {
        ApiService.getLookup('gallerycats/true').then(res => {
            this.documentCats = res;
            
            if (this.documentCats.length > 0) {
                this.selectedCategory = this.documentCats[0];
            }
        })
        .catch(err => {
            console.log(err);
        });
    },
    methods: {
        getDocuments(categoryId) {
            categoryId = categoryId || this.selectedCategory.id;

            if (!categoryId)
                return;

            ApiService.getDocsByGalleryCategory(categoryId, {PageNumber: (this.documentsPage - 1), PageMax: this.pageMax }).then(res => {
                this.documents = res.data;
                this.documentsPage = res.currentPage + 1;
                this.documentsTotalPages = res.totalPages;
            })
            .catch(err => {
                console.log(err);
            });
        },
        showImage(index) {
            this.lightboxIndex = index;
            this.lightboxVisible = true;
        },
        handleHide() {
            this.lightboxVisible = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 1000px !important;
        margin-bottom: 100px;
    }

    .category-menu {
        max-width: 400px !important;
        margin: auto !important;
    }
</style>