<template>
    <v-container fluid>
        <v-card class="pa-6">
            <div class="transition-swing text-h6">Settings</div>
            <v-row>
                <v-col>
                    <v-switch v-model="form.contractFree" label="Contact Free, Looking for Job">
                    </v-switch>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import ApiService from '../../ApiService';

export default {
    name: 'Settings',
    watch: {
        "form.contractFree": function () {
            if (this.isLoaded) {
                this.updateSettings();
            }
        }
    },
    data() {
        return {
            isLoaded: false,
            form: {
                contractFree: false
            }
        };
    },
    created() {
        ApiService.getUserSettings().then(res => {
            this.form.contractFree = res.contractFree;
        }).then(() => {
            this.isLoaded = true;
        });
    },
    methods: {
        updateSettings() {
            ApiService.updateUserSettings(this.form).then(res => {
                console.log(res);
            });
        }
    }
}
</script>