<template>
    <v-progress-circular indeterminate :color=color :size=size />
</template>
<script>
export default {
    name: 'Loader',
    props: {
        size: {
            type: Number,
            default: 36
        },
        color: {
            type: String,
            default: "primary"
        }
    }
}
</script>