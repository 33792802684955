<template>
    <v-container fluid>
        <Notification :eventBus=bus />
        <v-dialog
            v-model="deleteDialogOpen"
            max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Are you sure?
                </v-card-title>
                <v-card-text>
                    You are about to delete document
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteDialogOpen = false; documentToRemove = null">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="removeDocument(); deleteDialogOpen = false;">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteCatDialogOpen"
            max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Are you sure?
                </v-card-title>
                <v-card-text>
                    You are about to delete a category
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteCatDialogOpen = false">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="removeCategory(); deleteCatDialogOpen = false;">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
        <v-row>
            <v-col cols="4">
                <div class="transition-swing text-h6 pl-4">Categories</div>
                    <v-list-item 
                        class="category-create mt-2"
                        dense
                        ripple
                        @click="clearSelectedCategory">
                        <v-list-item-avatar
                            size="20">
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <span class="caption font-weight-light">New Category</span>
                        </v-list-item-content>
                    </v-list-item>
                <v-list
                    class="category-list"
                    v-if="documentCats != null && documentCats.length > 0">
                    <template v-for="(item, index) in documentCats">
                        <v-divider
                            v-if="index != 0"
                            :key="'d'+index"
                            color="white">
                        </v-divider>
                        <v-list-item
                            dense
                            ripple
                            :key="'i'+index"
                            :class="selectedCategory == item ? 'v-list-item--active' : ''"
                            @click="selectedCategory = item">
                            <v-list-item-content>
                                <v-list-item-title v-html="item.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
                <div v-else-if="documentCats != null && documentCats.length < 1">
                    No document categories added yet
                </div>
                <div v-else class="d-flex justify-center align-center">
                    <Loader />
                </div>
            </v-col>
            <v-col>
                <template v-if="selectedCategory != null">
                    <div class="d-inline-block mb-4" style="width: 300px; min-width: 100px">
                        <div class="d-flex justify-end">
                            <v-text-field 
                            filled
                            color="primary"
                                single-line
                                dense
                                hide-details="true"
                                label="Name" 
                                v-model="selectedCategory.name" 
                                :rules="categoryNameRules"
                                @change="updateCategoryName">
                            </v-text-field>
                            <v-btn
                                class="ml-2 transition-swing" icon x-small>
                                <v-icon
                                    @click="deleteCatDialogOpen = true"
                                    color="grey">
                                    mdi-trash-can-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-card class="pa-4 mb-4 file-upload" outlined>
                        <Upload 
                            :bus="bus"
                            label="Upload Documents"
                            placeholder="Your document files"
                            accept="application/pdf, text/plain, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            v-model="uploadFiles"
                            :disabled="isBusy"
                            counter 
                            multiple
                            v-if="selectedCategory != null" />
                        <template v-else>
                            <Loader />
                        </template>

                        <template v-if="uploadFiles != null && uploadFiles.length > 0">
                            <v-btn color="primary" @click="uploadDocument" v-if="!isBusy">Upload</v-btn>
                            <div v-else class="d-flex justify-center align-center">
                                <Loader />
                            </div>
                        </template>
                    </v-card>

                    <div class="pa-4 mb-8 documents-list">
                        <div class="font-weight-regular caption">Documents</div>
                        <v-list two-line v-if="documents != null && documents.length > 0">
                            <template v-for="(item, index) in documents">
                                <v-divider
                                    v-if="index != 0"
                                    :key="index"
                                ></v-divider>

                                <v-hover 
                                    :key="'hv'+index"
                                    v-slot="{ hover }">
                                    <v-list-item :key="'vlp-'+index"
                                        dense>
                                        <!-- <v-list-item-avatar>
                                            <v-img :src="item.avatar"></v-img>
                                        </v-list-item-avatar> -->
                                        <v-list-item-content>
                                            <v-list-item-title class="text-capitalize">{{ item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.createdDate }} -  {{ item.fileName }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="hover">
                                            <v-btn icon small>
                                                <v-icon color="grey lighten-1">mdi-download</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-action>
                                            <v-btn icon small v-if="hover" @click="showRemoveDocumentDialog(item)">
                                                <v-icon color="grey lighten-1">mdi-close</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-hover>
                            </template>
                        </v-list>
                        <div v-else-if="documents != null && documents.length < 1" class="d-flex justify-center">
                            <v-subheader>No documents in this category yet</v-subheader>
                        </div>
                        <div v-else class="d-flex justify-center align-center">
                            <Loader />
                        </div>
                        <div class="text-center mt-4" v-if="documents != null && documents.length > 0">
                            <v-pagination
                                v-model="documentsPage"
                                :length="documentsTotalPages"
                                :total-visible="8">
                            </v-pagination>
                        </div>
                    </div>
                </template>
                <div v-else>
                    <div class="transition-swing text-h6 pl-4">New Category</div>
                    <div class="pa-4 pt-0">
                        <v-form
                            ref="categoryForm">
                            <v-text-field 
                                label="Name" 
                                v-model="categoryForm.name" 
                                :disabled="isBusy"
                                :rules="categoryNameRules">
                            </v-text-field>
                        </v-form>

                        <v-btn 
                            class="primary mt-4"
                            color="primary" 
                            @click="createCategory" v-if="!isBusy">
                            Create
                        </v-btn>
                        <Loader v-else />
                    </div>
                </div>
            </v-col>
        </v-row>
        </v-card>
    </v-container>
</template>
<script>
import Vue from 'vue';
import ApiService from '../../ApiService';
import Upload from '../shared/Upload';
import Loader from '../shared/Loader';
import Notification from '../shared/Notification';

export default {
    name: 'Documents',
    components: {
        Upload, Loader, Notification
    },
    data() {
        return {
            bus: new Vue(),
            categoryForm: {
                name: null
            },
            isBusy: false,
            uploadFiles: [],
            uploadLimit: 10,
            documentCats: null,
            selectedCategory: null,
            documents: null,
            documentsPage: 1,
            documentsTotalPages: 0,
            pageMax: 10,
            deleteDialogOpen: false,
            deleteCatDialogOpen: false,
            documentToRemove: null,
            categoryNameRules: [
                v => !!v || 'Category name is required'
            ],
        }
    },
    created() {
        this.getCategories();
    },
    watch: {
        selectedCategory: function() {
            if (!this.selectedCategory)
                return;
            
            this.documentsPage = 1;
            this.getDocuments();
        },
        documentsPage: function() {
            this.getDocuments();
        }
    },
    methods: {
        addNotification(message) {
            this.bus.$emit('add', message);
        },
        clearNotifications() {
            this.bus.$emit('clear');
        },
        showRemoveDocumentDialog(document) {
            this.documentToRemove = document;
            this.deleteDialogOpen = true;
        },
        getCategories() {
            ApiService.getLookup('documentcats').then(res => {
                this.documentCats = res;
            })
            .catch(err => {
                console.log(err);
            });
        },
        getDocuments(categoryId) {
            categoryId = categoryId || this.selectedCategory.id;

            if (!categoryId)
                return;

            ApiService.getDocsByCategory(categoryId, {PageNumber: (this.documentsPage - 1), PageMax: this.pageMax }).then(res => {
                this.documents = res.data;
                this.documentsPage = res.currentPage + 1;
                this.documentsTotalPages = res.totalPages;
            })
            .catch(err => {
                console.log(err);
            });
        },

        clearSelectedCategory() {
            this.selectedCategory = null;
        },
        createCategory() {
            if (!this.$refs.categoryForm.validate())
                return;

            this.clearNotifications();
            this.isBusy = true;

            ApiService.createDocumentCategory({ Name: this.categoryForm.name }).then(res => {
                if (res.error) {
                    this.addNotification(res.error);
                    return;
                }

                this.$refs.categoryForm.reset();
                this.getCategories();
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            })
        },
        updateCategoryName(name) {
            if (this.selectedCategory && name && name !== '') {
                ApiService.updateDocumentCategory(this.selectedCategory.id, { Name: name }).then(res => {
                    if (res.error) {
                        console.log(res.error);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        },
        removeCategory() {
            if (!this.selectedCategory)
                return;
            
            this.clearNotifications();
            this.isBusy = true;

            let categoryId = this.selectedCategory.id;

            ApiService.removeDocumentCategory(categoryId).then((res) => {
                if (res.error) {
                    this.addNotification(res.error);
                    return;
                }

                this.selectedCategory = null;
                this.getCategories();
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            })
        },

        removeDocument() {
            if (!this.documentToRemove)
                return;

            this.clearNotifications();
            this.isBusy = true;

            var documentId = this.documentToRemove.id;

            ApiService.removeDocumentFromCategory(documentId).then((res) => {
                if (res.error) {
                    this.addNotification(res.error);
                    return;
                }

                let index = this.documents.indexOf(this.documentToRemove);
                if (index >= 0) {
                    this.documents.splice(index, 1);
                }

                this.documentToRemove = null;
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            });
        },

        uploadDocument() {
            if (!this.selectedCategory)
                return;

            this.clearNotifications();
            this.isBusy = true;

            var categoryId = this.selectedCategory.id;

            ApiService.uploadDocument('document/upload/category', 
            { 
                Files: this.uploadFiles, 
                Fields: { CategoryId: categoryId } 

            }).then(() => {
                this.getDocuments();
                this.bus.$emit('reset');
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .file-upload {
        max-width: 400px;
    }

    .v-application .overline {
        font-size: 0.8em !important;
    }

    .documents-list {
        min-height: 120px;
    }

    .category-list {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>