<template>
    <v-container fluid>
        <v-card
            v-if="loaded"
            class="mt-8 pa-8">
            <v-card-title class="font-weight-normal text-h4 pb-8">{{title}}</v-card-title>
            <v-card-subtitle class="text-caption">{{createdDate}}</v-card-subtitle>
            <v-card-text v-html="description" class="blog-content" />

            <v-card-actions>
                <v-spacer />
                <ShareNetwork
                    class="mr-2 social-button"
                    network="Facebook"
                    :url="currentPageUrl"
                    :title="title"
                    :description="description"
                    :quote="title"
                    hashtags="sejdinovski">
                    <v-icon color="primary">mdi-facebook</v-icon>
                </ShareNetwork>
                <ShareNetwork
                    class="mr-2 social-button"
                    network="Twitter"
                    :url="currentPageUrl"
                    :title="title"
                    :description="description"
                    :quote="title"
                    hashtags="sejdinovski">
                    <v-icon color="primary">mdi-twitter</v-icon>
                </ShareNetwork>
                 <ShareNetwork
                    class="social-button"
                    network="Email"
                    :url="currentPageUrl"
                    :title="title"
                    :description="description"
                    :quote="title"
                    hashtags="sejdinovski">
                    <v-icon color="grey">mdi-email</v-icon>
                </ShareNetwork>
            </v-card-actions>
        </v-card>
        <v-skeleton-loader v-else type="article, actions" class="mt-8 pa-8" />
    </v-container>
</template>
<script>
import Vue from 'vue';
import ApiService from '../../ApiService';
import { ShareNetwork } from 'vue-social-sharing';

export default {
    name: 'BlogPreview',
    components: {
        ShareNetwork
    },
    data() {
        return {
            title: null,
            description: null,
            createdDate: null,
            loaded: false
        };
    },
    computed: {
        contentId: function() {
            return this.$route.params.id;
        },
        currentPageUrl: function() {
            return window.location.href;
        }
    },
    created() {
        if (this.contentId) {
            this.getBlogContent().then(() => {
                Vue.nextTick(() => this.$emit('init'));
            });
        }
    },
    methods: {
        getBlogContent() {
            return ApiService.getContentById(this.contentId).then(res => {
                this.title = res.title;
                this.description = res.description;
                this.createdDate = res.createdDate;
            })
            .catch(err => {
                console.log(err);
            })
            .then(() => {
                this.loaded = true;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 1000px !important;
        margin-bottom: 100px;
    }

    .blog-content {
        ::v-deep img {
            width: 100%;
        }

        color: #000 !important;
    }

    .social-button {
        text-decoration: none;
    }
</style>