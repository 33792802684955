<template>
    <v-alert type="error" v-if="Notifications.length > 0">
        <div class="" role="alert" id="notifications" 
            v-for="(message, index) in Notifications"
            :key="index"
            :v-model="message">
            {{message}}
        </div>
    </v-alert>
</template>

<script>
export default {
    name: 'Notification',
    props: {
        eventBus: Object
    },
    data() {
        return {
            Notifications: []
        }
    },
    created() {
        this.eventBus.$on('clear', this.clearNotifications);
        this.eventBus.$on('add', this.addNotification);
    },
    methods: {
        addNotification(message) {
            this.Notifications.push(message);
        },
        clearNotifications() {
            this.Notifications = [];
        }
    }
}
</script>
<style lang="scss" scoped>
    .v-alert {
        padding: 12px;
        font-size: 0.9em;
    }
</style>