<template>
    <v-container fluid>
        <Notification :eventBus=bus />
        <v-dialog
            v-model="deleteDialogOpen"
            max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Are you sure?
                </v-card-title>
                <v-card-text>
                    You are about to delete a photo
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteDialogOpen = false; documentToRemove = null">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="removeDocument(); deleteDialogOpen = false;">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteCatDialogOpen"
            max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Are you sure?
                </v-card-title>
                <v-card-text>
                    You are about to delete a category
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteCatDialogOpen = false">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="removeCategory(); deleteCatDialogOpen = false;">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <v-row>
                <v-col cols="4">
                    <div class="transition-swing text-h6 pl-4">Categories</div>
                    <v-list-item 
                        class="category-create mt-2"
                        dense
                        ripple
                        @click="clearSelectedCategory">
                        <v-list-item-avatar
                            size="20">
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <span class="caption font-weight-light">New Category</span>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list
                        class="category-list"
                        v-if="galleryCats != null && galleryCats.length > 0">
                        <template v-for="(item, index) in galleryCats">
                            <v-divider
                                v-if="index != 0"
                                :key="'d'+index"
                                color="white">
                            </v-divider>
                            <v-list-item
                                dense
                                ripple
                                :key="'i'+index"
                                :class="selectedCategory == item ? 'v-list-item--active' : ''"
                                @click="selectedCategory = item">
                                <v-list-item-content>
                                    <v-list-item-title v-html="item.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                    <div v-else-if="galleryCats != null && galleryCats.length < 1">
                        No document categories added yet
                    </div>
                    <div v-else class="d-flex justify-center align-center">
                        <Loader />
                    </div>
                </v-col>
                <v-col cols="8">
                    <template v-if="selectedCategory != null">
                        <div class="d-inline-block mb-4" style="width: 300px; min-width: 100px">
                            <div class="d-flex justify-end">
                                <v-text-field 
                                filled
                                color="primary"
                                    single-line
                                    dense
                                    hide-details="true"
                                    label="Name" 
                                    v-model="selectedCategory.name" 
                                    :rules="categoryNameRules"
                                    @change="updateCategoryName">
                                </v-text-field>
                                <v-btn
                                    class="ml-2 transition-swing" icon x-small>
                                    <v-icon
                                        @click="deleteCatDialogOpen = true"
                                        color="grey">
                                        mdi-trash-can-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-card class="pa-4 mb-4 file-upload" outlined>
                            <Upload 
                                :bus="bus"
                                label="Upload Photos"
                                placeholder="Your gallery photos"
                                accept="image/jpg, image/jpeg, image/png"
                                v-model="uploadFiles"
                                :disabled="isBusy"
                                counter 
                                multiple
                                v-if="selectedCategory != null" />
                            <template v-else>
                                <Loader />
                            </template>

                            <template v-if="uploadFiles != null && uploadFiles.length > 0">
                                <v-btn color="primary" @click="uploadDocument" v-if="!isBusy">Upload</v-btn>
                                <div v-else class="d-flex justify-center align-center">
                                    <Loader />
                                </div>
                            </template>
                        </v-card>
                        
                        <div class="font-weight-regular caption">Photos</div>

                        <v-row 
                            v-if="documents != null && documents.length > 0" 
                            class="pr-4">
                            <v-col cols="3"
                                v-for="(doc, index) in documents" 
                                :key="index">
                                <v-hover v-slot="{hover}">
                                    <v-card tile flat outlined class="pa-1">
                                        <v-img
                                            :src="doc.thumbnail + '/256'"
                                            :lazy-src="doc.thumbnail + '/256'"
                                            aspect-ratio="1"
                                            max-width="140px">

                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5">
                                                    </v-progress-circular>
                                                </v-row>
                                            </template>
                                            
                                            <v-btn 
                                                color="transparent" 
                                                @click="showRemoveDocumentDialog(doc)" 
                                                width="100%" 
                                                height="100%"
                                                :class="{ 'on-hover': hover, 'imageButton': true }">

                                                <v-icon color="rgba(255, 255, 255, 0.3)" large>mdi-close</v-icon>
                                            </v-btn>
                                        </v-img>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                        <div v-else-if="documents != null && documents.length < 1" class="d-flex justify-center">
                            <v-subheader>No documents in this category yet</v-subheader>
                        </div>
                        <div v-else class="d-flex justify-center align-center">
                            <Loader />
                        </div>
                        <div class="text-center mt-4" v-if="documents != null && documents.length > 0">
                            <v-pagination
                                v-model="documentsPage"
                                :length="documentsTotalPages"
                                :total-visible="8">
                            </v-pagination>
                        </div>
                    </template>
                    <div v-else>
                        <div class="transition-swing text-h6 pl-4">New Category</div>
                        <div class="pa-4 pt-0">
                            <v-form
                                ref="categoryForm">
                                <v-text-field 
                                    label="Name" 
                                    v-model="categoryForm.name" 
                                    :disabled="isBusy"
                                    :rules="categoryNameRules">
                                </v-text-field>
                            </v-form>

                            <v-btn 
                                class="primary mt-4"
                                color="primary" 
                                @click="createCategory" v-if="!isBusy">
                                Create
                            </v-btn>
                            <Loader v-else />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import Vue from 'vue';
import ApiService from '../../ApiService';
import Upload from '../shared/Upload';
import Loader from '../shared/Loader';
import Notification from '../shared/Notification';

export default {
    name: 'Gallery',
    components: {
        Upload, Loader, Notification
    },
    data() {
        return {
            bus: new Vue(),
            categoryForm: {
                name: null
            },
            isBusy: false,
            uploadFiles: [],
            uploadLimit: 10,
            galleryCats: null,
            selectedCategory: null,
            documents: null,
            documentsPage: 1,
            documentsTotalPages: 0,
            pageMax: 12,
            deleteDialogOpen: false,
            deleteCatDialogOpen: false,
            documentToRemove: null,
            categoryNameRules: [
                v => !!v || 'Category name is required'
            ],
        }
    },
    created() {
        this.getCategories();
    },
    watch: {
        selectedCategory: function() {
            if (!this.selectedCategory)
                return;
            
            this.documentsPage = 1;
            this.getDocuments();
        },
        documentsPage: function() {
            this.getDocuments();
        }
    },
    methods: {
        addNotification(message) {
            this.bus.$emit('add', message);
        },
        clearNotifications() {
            this.bus.$emit('clear');
        },
        showRemoveDocumentDialog(document) {
            this.documentToRemove = document;
            this.deleteDialogOpen = true;
        },

        getCategories() {
            ApiService.getLookup('gallerycats').then(res => {
                this.galleryCats = res;
            })
            .catch(err => {
                console.log(err);
            });
        },
        getDocuments(categoryId) {
            categoryId = categoryId || this.selectedCategory.id;

            if (!categoryId)
                return;

            ApiService.getDocsByGalleryCategory(categoryId, {PageNumber: (this.documentsPage - 1), PageMax: this.pageMax }).then(res => {
                this.documents = res.data;
                this.documentsPage = res.currentPage + 1;
                this.documentsTotalPages = res.totalPages;
            })
            .catch(err => {
                console.log(err);
            });
        },

        clearSelectedCategory() {
            this.selectedCategory = null;
        },
        createCategory() {
            if (!this.$refs.categoryForm.validate())
                return;

            this.clearNotifications();
            this.isBusy = true;

            ApiService.createGalleryCategory({ Name: this.categoryForm.name }).then(res => {
                if (res.error) {
                    this.addNotification(res.error);
                    return;
                }

                this.$refs.categoryForm.reset();
                this.getCategories();
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            })
        },
        updateCategoryName(name) {
            if (this.selectedCategory && name && name !== '') {
                ApiService.updateGalleryCategory(this.selectedCategory.id, { Name: name }).then(res => {
                    if (res.error) {
                        console.log(res.error);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        },
        removeCategory() {
            if (!this.selectedCategory)
                return;
            
            this.clearNotifications();
            this.isBusy = true;

            let categoryId = this.selectedCategory.id;

            ApiService.removeGalleryCategory(categoryId).then((res) => {
                if (res.error) {
                    this.addNotification(res.error);
                    return;
                }

                this.selectedCategory = null;
                this.getCategories();
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            })
        },

        removeDocument() {
            if (!this.documentToRemove)
                return;

            this.clearNotifications();
            this.isBusy = true;

            var documentId = this.documentToRemove.id;

            ApiService.removeDocumentFromGallery(documentId).then((res) => {
                if (res.error) {
                    this.addNotification(res.error);
                    return;
                }

                let index = this.documents.indexOf(this.documentToRemove);
                if (index >= 0) {
                    this.documents.splice(index, 1);
                }

                this.documentToRemove = null;
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            });
        },

        uploadDocument() {
            if (!this.selectedCategory)
                return;

            this.clearNotifications();
            this.isBusy = true;

            var categoryId = this.selectedCategory.id;

            ApiService.uploadDocument('gallery/upload', 
            { 
                Files: this.uploadFiles, 
                Fields: { CategoryId: categoryId } 

            }).then(() => {
                this.getDocuments(categoryId);
                this.bus.$emit('reset');
            })
            .catch(err => {
                this.addNotification(err);
            })
            .finally(() => {
                this.isBusy = false;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .file-upload {
        max-width: 400px;
    }

    .v-application .overline {
        font-size: 0.8em !important;
    }

    .documents-list {
        min-height: 120px;
    }

    .category-list {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>