<template>
    <v-container>
        <h1 class="font-weight-thin display-2 mb-6 mt-10 text-center" text>Contact Sandi</h1>

        <div class="mb-8 text-center font-weight-light">
            <v-chip
                class="ma-2"
                color="primary"
                label
                text-color="white">
                sandi
                <v-icon small class="pl-1 pr-1">
                    mdi-at
                </v-icon>
                sejdinovski.com
            </v-chip> 
        </div>
        <v-expand-transition>
            <div v-if="!messageSentSuccess">
                <v-form
                    ref="form"
                    v-model="isFormValid">
                    <v-text-field
                        :readonly="isBusy"
                        v-model="form.email"
                        class="mb-4"
                        label="Email"
                        hide-details="auto"
                        :rules="emailRules">
                    </v-text-field>
                    <v-text-field
                        :readonly="isBusy"
                        v-model="form.phone"
                        class="mb-8"
                        label="Phone"
                        hide-details="auto">
                    </v-text-field>
                    <v-textarea
                        :readonly="isBusy"
                        v-model="form.message"
                        auto-grow
                        label="Your message"
                        rows="4"
                        row-height="40"
                        :rules="messageRules"
                        filled>
                    </v-textarea>
                </v-form>
                <v-btn color="primary mt-4" @click="submit" v-if="!isBusy">
                    Send Message
                </v-btn>
                <Loader v-if="isBusy" />
            </div>
            <v-alert 
                outlined
                v-else
                color="primary"
                icon="mdi-email"
                type="success">
                Your message has successfully been sent!
            </v-alert>  
        </v-expand-transition>
    </v-container>
</template>
<script>
import ApiService from '../../ApiService';
import Loader from '../shared/Loader';

export default {
    name: 'Contact',
    components: {
        Loader
    },
    data() {
        return {
            isBusy: false,
            isFormValid: true,
            form: {
                phone: null,
                email: null,
                message: null
            },
            emailRules: [
                v => !!v || 'Email is required'
            ],
            messageRules: [
                v => !!v || 'Message is required'
            ],
            messageSentSuccess: false
        };
    },
    methods: {
        submit() {
            if (!this.$refs.form.validate())
                return;

            this.isBusy = true;
            ApiService.sendUserContactMessage(this.form).then(res => {
                if (!res.error) {
                    this.messageSentSuccess = true;
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => this.isBusy = false);
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 700px !important;
        margin-bottom: 100px;
    }
</style>